<template>
  <div class="fluid" id="requestList">
    <div v-if="!getIsLoading">
      <div v-if="permission.read_perm === 1" class="request-list-div">
        <v-card icon="mdi-currency-usd" title="Currency" class="px-5 py-3">
          <v-row>
            <v-col cols="12">
              <v-toolbar
                class="request-list-toolbar-1"
                flat
                style="height:60px; border-radius:10px 10px 0 0; "
              >
                <v-btn
                  class="mx-2 elevation-4"
                  dark
                  large
                  color="cyan"
                  style="position:relative; top:-20px; left:-10px; height:80px; width:80px;"
                >
                  <v-icon large dark>
                    mdi-calendar-multiple-check
                  </v-icon>
                </v-btn>
                <v-toolbar-title>Request List</v-toolbar-title>
                <v-divider class="mx-6" inset vertical></v-divider>
                <div class="request-list-toolbar-div">
                  <v-text-field
                    v-model="paramAPI.keyword"
                    label="Search here"
                    type="search"
                    outlined
                    dense
                    @keyup.enter="searchEnter"
                    style="position: relative; top:15px; width:10px;"
                  ></v-text-field>
                  <div
                    style="width:120px;position: relative; top:15px; margin-left:10px;"
                  >
                    <v-select
                      v-model="paramAPI.room_id"
                      :items="getRoomDropdown"
                      item-text="name"
                      item-value="id"
                      return-id
                      :item-disabled="disableItem"
                      class="elevation-0"
                      hide-no-data
                      hide-details
                      label="Select Room"
                      return
                      dense
                      outlined
                    ></v-select>
                  </div>
                  <div style="width:150px;">
                    <v-select
                      label="Status"
                      style="position: relative; top:15px; margin-left:10px; font-size:12px;"
                      v-model="paramAPI.status"
                      :items="[
                        { id: '', name: 'All' },
                        { id: 1, name: 'Approved' },
                        { id: 0, name: 'Pending' },
                        { id: -1, name: 'Rejected' }
                      ]"
                      item-text="name"
                      item-value="id"
                      outlined
                      return-id
                      dense
                    ></v-select>
                  </div>
                </div>
              </v-toolbar>
              <v-toolbar
                class="request-list-toolbar-2"
                flat
                style="height:60px; border-radius:10px 10px 0 0; border-bottom:1px solid #e0e0e0;"
              >
                <v-select
                  v-model="paramAPI.room_id"
                  :items="getRoomDropdown"
                  item-text="name"
                  item-value="id"
                  return-id
                  :item-disabled="disableItem"
                  class="elevation-0"
                  hide-no-data
                  hide-details
                  label="Select Room"
                  return
                  dense
                  outlined
                ></v-select>
                <div style="width:150px;">
                  <v-select
                    label="Status"
                    style="position: relative; top:15px; margin-left:10px; font-size:12px;"
                    v-model="paramAPI.status"
                    :items="[
                      { id: '', name: 'All' },
                      { id: 1, name: 'Approved' },
                      { id: 0, name: 'Pending' },
                      { id: -1, name: 'Rejected' }
                    ]"
                    clearable
                    item-text="name"
                    item-value="id"
                    outlined
                    return-id
                    dense
                  ></v-select>
                </div>
              </v-toolbar>
              <v-toolbar
                class="request-list-toolbar-2"
                flat
                style="height:60px; border-radius:10px 10px 0 0; border-bottom:1px solid #e0e0e0;"
              >
                <v-text-field
                  v-model="paramAPI.keyword"
                  label="Search here"
                  type="search"
                  outlined
                  dense
                  @keyup.enter="searchEnter"
                  style="position: relative; top:15px; width:10px;"
                ></v-text-field>
              </v-toolbar>
            </v-col>

            <v-col cols="12">
              <v-data-table
                class="elevation-1 "
                style="cursor:pointer;"
                :headers="headers"
                :items="getRoomBookingList"
                :items-per-page="10"
                :loading="loading"
                @click:row="rowClick"
              >
                <template v-slot:[`item.start_use`]="{ item }">
                  <div style="padding:0; display:flex; flex-direction:row;">
                    {{ convertDate(item.start_use) }}
                  </div>
                </template>
                <template v-slot:[`item.end_use`]="{ item }">
                  <div style="padding:0; display:flex; flex-direction:row;">
                    {{ convertDate(item.end_use) }}
                  </div>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  <div style="padding:0; display:flex; flex-direction:row;">
                    {{ convertDate(item.created_at) }}
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div
                    :style="
                      `padding:0; display:flex;   flex-direction:row; color:${
                        item.status.id === 1
                          ? 'green'
                          : item.status.id === -1
                          ? 'red'
                          : 'grey'
                      }`
                    "
                  >
                    {{ item.status.name }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div v-else style="height:83vh; padding-top:50px;">
        <AccessDenied />
      </div>
    </div>
    <Loader v-else />
  </div>
</template>
<script>
import Loader from '../../../components/common/loader/CircularProgress'
import AccessDenied from '../../../components/common/accessDenied/accessDenied'

import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  data: () => ({
    drawer: false,

    menu_key: 23,
    permission: {},
    application: {
      id: 9,
      name: 'Room Activity'
    },
    paramAPI: {
      keyword: '',
      offset: 0,
      limit: 1000,
      status: '',
      room_id: ''
    },
    headers: [
      {
        text: 'Booking No',
        value: 'booking_no',
        align: 'left',
        sortable: true
      },
      {
        text: 'Ruang',
        value: 'room.name',
        align: 'left',
        sortable: true
      },
      {
        text: 'Tgl/Waktu Pakai',
        value: 'start_use',
        align: 'left',
        sortable: true
      },
      {
        text: 'Tgl/Waktu Berakhir',
        value: 'end_use',
        align: 'left',
        sortable: true
      },
      {
        text: 'Diajukan Oleh',
        value: 'created_by_name',
        align: 'left',
        sortable: true
      },
      {
        text: 'Department',
        value: 'department_name',
        align: 'left',
        sortable: true
      },
      {
        text: 'Created at',
        value: 'created_at',
        align: 'left',
        sortable: true
      },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: true
      }
    ],
    showForm: false,
    totalBookingData: 1,
    options: {},
    loading: false,
    getRoomDropdown: []
  }),
  components: {
    Loader,
    AccessDenied
  },
  watch: {
    'paramAPI.status'() {
      this.initBookingList()
    },
    'paramAPI.room_id'() {
      this.initBookingList()
    }
  },
  created() {
    this.setIsLoading(true)
    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            this.permission = result.data
            this.setPermission(result.data)
            this.dropdown()
            this.initBookingList()
            console.log(this.permission)
            setTimeout(() => {
              this.setIsLoading(false)
            }, 1000)
          })
          .catch(error => {
            if (error) {
              this.permission = {
                read_perm: 0
              }
              this.setPermission({ read_perm: 0 })
              setTimeout(() => {
                this.setIsLoading(false)
              }, 1000)
            }
          })
      } else {
        this.permission = {
          read_perm: 0
        }
        this.setPermission({ read_perm: 0 })
        setTimeout(() => {
          this.setIsLoading(false)
        }, 1000)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getLogin',
      'getRoomBookingList',
      'getUserProfile',
      'getIsLoading'
    ])
  },
  methods: {
    ...mapActions([
      'menu_access',
      'logout',
      'roomBookingList',
      'bookingDetail',
      'roomDropdown'
    ]),
    ...mapMutations([
      'setBookingDetail',
      'setRoomBookingId',
      'setIsLoading',
      'setPermission'
    ]),
    searchEnter() {
      this.initBookingList()
    },
    showMsgDialog(pModalType, pStatusMsg) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            title:
              pModalType === 'success'
                ? '<strong>Success</strong>'
                : `<strong>${pModalType}</strong>`,
            icon: pModalType,
            html: pStatusMsg,
            showConfirmButton: true,
            timer: 5000
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    dropdown() {
      // this.buildingDropdown()
      this.roomDropdown(this.getUserProfile)
        .then(res => {
          const newArr = [
            {
              id: '',
              level: '',
              name: ''
            }
          ]
          if (this.getUserProfile.sanqua_company_id !== 2) {
            for (let i = 0; i < res.data.length; i++) {
              if (
                res.data[i].id === 1 ||
                res.data[i].id === 2 ||
                res.data[i].id === 3 ||
                res.data[i].id === 4 ||
                res.data[i].id === 5 ||
                res.data[i].id === 10
              ) {
                const obj = {
                  id: res.data[i].id,
                  level: res.data[i].level,
                  name: res.data[i].name
                }
                newArr.push(obj)
              }
            }
          } else {
            for (let i = 0; i < res.data.length; i++) {
              const obj = {
                id: res.data[i].id,
                level: res.data[i].level,
                name: res.data[i].name
              }
              newArr.push(obj)
            }
          }
          // for (let i = 0; i < res.data.length; i++) {
          //   const obj = {
          //     id: res.data[i].id,
          //     level: res.data[i].level,
          //     name: res.data[i].name
          //   }
          //   newArr.push(obj)
          // }
          this.getRoomDropdown = newArr
        })
        .catch(err => {
          console.log(err)
        })
    },
    drawerSet(val) {
      this.drawer = val
    },

    initBookingList() {
      this.loading = true
      this.roomBookingList(this.paramAPI)
        .then(res => {
          console.log(res)
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, please contact an Admin'
              : 'Something went wrong, please contact an Admin'
          )
        })
    },

    rowClick(pValue) {
      this.setRoomBookingId(pValue)
      this.bookingDetail()
        .then(res => {
          console.log(res)
          if (res.status_code === '00') {
            this.setBookingDetail(res)
            this.roomDropdown()
            this.$router.push('/meeting-activity/request-list/approval')
          } else {
            this.showMsgDialog('error', 'status code: ' + res.status_code)
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, please contact an Admin'
              : 'Something went wrong, please contact an Admin'
          )
        })
    },
    convertDate(raw) {
      const date = new Date(raw)
      return date.toLocaleString('id')
    },
    disableItem(item) {
      return item.id === 6
    }
  }
}
</script>
<style lang="scss" src="">
#requestList {
  margin-bottom: 10px;
  .request-list-div {
    padding: 40px 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .request-list-toolbar-1 {
      .request-list-toolbar-div {
        width: 50%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .request-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #requestList {
    .request-list-div {
      .request-list-toolbar-1 {
        .request-list-toolbar-div {
          display: none;
        }
      }
      .request-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
